import './App.css';
import React from "react";
import Index from '../container/Index/index';
function App() {

  return (
    <>
      <div className="App">
        <Index />
      </div>
    </>
  );
}


export default App;
