import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.css"
import ClientDataInput from "../../component/ClientDataInput";
import EightLetters from "../../component/EightLetters";
import ArrowLegend from "../../component/ArrowLegend";
import YearFortune100 from "../../component/YearFortune100";
import Horosope from "../../component/Horosope";

// Reusable loading spinner component


export default function Simple(props) {

    const dateRef = useRef(null);


    useEffect(() => {
        if (props.year10Ready) {
            scrollToHideDate()
        }
    }, [props.year10Ready])


    const scrollToHideDate = () => {
        if (dateRef.current) {
            // Get the position of the date div
            const dateDivPosition = dateRef.current.getBoundingClientRect().bottom + window.scrollY;

            // Scroll the window to hide the date div
            document.querySelector("body").scrollTo({
                top: dateDivPosition,
                behavior: 'smooth'
            });
        }
    };

    const yearFortuneComponent = (
        <YearFortune100
            inputDate={props.inputDate}
            isMale={props.isMale}
            yearfortune100Result={props.yearfortune100Result}
            setSelected10YearRange={props.setSelected10YearRange}
            setSelectedYear10={props.setSelectedYear10}
            setSelectedYear10IndexNumber={props.setSelectedYear10IndexNumber}
            setSelectedYear={props.setSelectedYear}
            setSelectedMonth={props.setSelectedMonth}
            setYearfortune100Result={props.setYearfortune100Result}
            selectedLetterIndex={props.selectedLetterIndex}
            setSelectedLetterIndex={props.setSelectedLetterIndex}
            selectedLetter={props.selectedLetter}
            setSelectedLetter={props.setSelectedLetter}
            extraInfoMask={props.extraInfoMask}
            isDLetterReady={props.isDLetterReady}
            setIsDLetterReady={props.setIsDLetterReady}
            msonIdPartyShowList={props.msonIdPartyShowList}
            setMsonIdPartyShowList={props.setMsonIdPartyShowList}
            letterAddressAllDateObj={props.letterAddressAllDateObj}
            setLetterAddressAllDateObj={props.setLetterAddressAllDateObj}
        />
    );

    return <div className={styles.container}>
        {props.eightLettersResult ? (
            <EightLetters
                inputDate={props.inputDate}
                isMale={props.isMale}
                extraInfoMask={props.extraInfoMask}
                selectedYear10={props.selectedYear10}
                selected10YearRange={props.selected10YearRange}
                yearfortune100Result={props.yearfortune100Result}
                selectedYear={props.selectedYear}
                selectedMonth={props.selectedMonth}
                eightLettersResult={props.eightLettersResult}
                setEightLettersResult={props.setEightLettersResult}
                selectedLetterIndex={props.selectedLetterIndex}
                setSelectedLetterIndex={props.setSelectedLetterIndex}
                selectedLetter={props.selectedLetter}
                setSelectedLetter={props.setSelectedLetter}
                year10Ready={props.year10Ready}
                selectedYear10IndexNumber={props.selectedYear10IndexNumber}
                addressSchema={props.addressSchema}
                isDLetterReady={props.isDLetterReady}
                setIsDLetterReady={props.setIsDLetterReady}
                msonIdPartyShowList={props.msonIdPartyShowList}
                setMsonIdPartyShowList={props.setMsonIdPartyShowList}
                letterAddressAllDateObj={props.letterAddressAllDateObj}
                setLetterAddressAllDateObj={props.setLetterAddressAllDateObj}
                analysisResult={props.analysisResult}
                setAnalysisResult={props.setAnalysisResult}
                setIsAnalysisReady={props.setIsAnalysisReady}
                getAnalysisOriginal={props.getAnalysisOriginal}
                shouldDrawDLetter={false}
            />
        ) : (
            <props.LoadingSpinner />
        )}

        
        {props.inputDate && props.eightLettersResult && yearFortuneComponent ? yearFortuneComponent :  <props.LoadingSpinner />}


        {/* {props.inputDate && props.eightLettersResult ? (
            <YearFortune100
                inputDate={props.inputDate}
                isMale={props.isMale}
                yearfortune100Result={props.yearfortune100Result}

                setSelected10YearRange={props.setSelected10YearRange}
                setSelectedYear10={props.setSelectedYear10}
                setSelectedYear10IndexNumber={props.setSelectedYear10IndexNumber}
                setSelectedYear={props.setSelectedYear}
                setSelectedMonth={props.setSelectedMonth}
                setYearfortune100Result={props.setYearfortune100Result}
                // setYear10Ready={props.setYear10Ready}
                selectedLetterIndex={props.selectedLetterIndex}
                setSelectedLetterIndex={props.setSelectedLetterIndex}
                selectedLetter={props.selectedLetter}
                setSelectedLetter={props.setSelectedLetter}
                extraInfoMask={props.extraInfoMask}
                // to indicate wither deviated letter are ready
                isDLetterReady={props.isDLetterReady}
                setIsDLetterReady={props.setIsDLetterReady}
                // for mson selector
                // share from eightLetter to horoscope tab page
                msonIdPartyShowList={props.msonIdPartyShowList}
                setMsonIdPartyShowList={props.setMsonIdPartyShowList}
                letterAddressAllDateObj={props.letterAddressAllDateObj}
                setLetterAddressAllDateObj={props.setLetterAddressAllDateObj}
            />
        ) :  <props.LoadingSpinner />} */}

    </div>

}