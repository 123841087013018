import { useEffect } from 'react'
import { get } from '../../axios'
import React, { useState } from "react"
import YearFortune1 from '../YearFortune1'
import './index.css'
import { compareBirthday } from "../../Helper/DateTimeHelper";
import { globalData } from "../../Helper/global";
export default function YearFortune100(props) {

    // const [yearfortune100Result, setYearfortune100Result] = useState()
    const [selected10YearRange, setSelected10YearRange] = useState()

    // const [yearRange, setYearRange] = useState([]);
    // const [inputDate, setInputDate] = useState();
    // const [isMale, setIsMale] = useState();

    //open details
    const [isYearsOpen, setIsYearsOpen] = useState(false);
    const [isMonthsOpen, setIsMonthsOpen] = useState(false);


    //for selection ui
    const [selectedYear10, setSelectedYear10] = useState(0);
    const [selectedYear10IndexNumber, setSelectedYear10IndexNumber] = useState(-1);
    const [selectedYearIndexNumber, setSelectedYearIndexNumber] = useState(-1);
    const [selectedYear, setSelectedYear] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(0);

    //for drawing fortune1
    const [drawFortune1EndYear, setDrawFortune1EndYear] = useState(0);


    // useEffect(() => {
    //     setInputDate(props.inputDate)
    //     setIsMale(props.isMale)
    //     if (props && props.inputDate && (props.inputDate != inputDate || props.isMale != isMale)) {
    //         getYearFortune(props.inputDate, props.isMale)
    //     }
    // }, [props.inputDate, props.isMale])


    const drawYearFortune10 = (yearfortune100Param) => {
        if (yearfortune100Param && yearfortune100Param.isSuccess) {

            //get the year of birthday
            const birthday = new Date(yearfortune100Param.birthday)
            const yearOfBirth = isNaN(birthday.getDate()) ? 0 : birthday.getFullYear

            let result = [];
            let resultYearRange = [];
            const tenYearFortune = yearfortune100Param.tenYearFortune
            for (const itemKey in tenYearFortune) {
                resultYearRange.push(tenYearFortune[itemKey].year)
            }
            //record the index of looping tenYearFortune
            //To find out 1st 未入運 item 
            let indexNumber = 0

            for (const itemKey in tenYearFortune) {
                let year = tenYearFortune[itemKey].year
                result.push(
                    <YearFortune1
                        key={"tenYearFortune" + year + "i" + indexNumber}
                        indexNumber={indexNumber}
                        yearfortune100Result={yearfortune100Param}
                        yearRange={resultYearRange}
                        //click
                        isClickable={true}
                        onChildClicked={onClickYearFortune10}
                        //first year is 0 from server side
                        year={year == 0 ? yearOfBirth : year}
                        age={tenYearFortune[itemKey].age}
                        skyStem={tenYearFortune[itemKey].yearPillar[0]}
                        earthBranch={tenYearFortune[itemKey].yearPillar[1]}
                        selectedYear={selectedYear10}
                        selectedYearIndexNumber={selectedYear10IndexNumber}

                        chi={tenYearFortune[itemKey].chi}

                        selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                        selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}
                    />
                )
                indexNumber++
            }
            return <div className='YF10container'>{result}</div>
        } else {
            return undefined
        }
    }

    const onClickYearFortune10 = (yearfortune100Param, yearRangeParam, year, fullDate, indexNumber) => {
        //To dim the non-selected
        setSelectedYear10(year)
        setSelectedYear10IndexNumber(indexNumber)
        setSelectedYear(0); //reset last selected item
        setSelectedMonth(0);//reset last selected item
        setSelectedYearIndexNumber(-1)//reset last selected item


        //To set data in parent
        props.setSelectedYear10(year)
        props.setSelectedYear(0); //reset last selected item
        props.setSelectedMonth(0);//reset last selected item
        props.setSelectedYear10IndexNumber(indexNumber)


        let index = indexNumber;

        /**
         * case 1. Clicking the last fortune: end year is current year + 9. We don't have next fortune 10.
         * case 2. Clicking not the last fortune: end year is next year - 1 year
         * case 3. If selected year is going to enter fortune within 10 year. The end year less than selectedYear. No
         * record will show up. It is expected behaviour not entering fortune 
         */


        let endYear = (index + 1 >= yearRangeParam.length) ? year + 9 : yearRangeParam[index + 1] - 1
        setSelected10YearRange(year + "-" + endYear)
        props.setSelected10YearRange(year + "-" + endYear)
        setDrawFortune1EndYear(endYear)
        // drawYearFortune1(yearfortune100Param, year, endYear) //流年
        setIsYearsOpen(true)
    }



    const drawYearFortune1 = (yearfortune100Param, startYear, endYear) => {
        if (yearfortune100Param && yearfortune100Param.isSuccess) {
            let result = [];
            const yearFortune = yearfortune100Param.yearFortune

            for (let i = 0; (startYear + i) <= endYear; i++) {
                let itemKey = startYear + i
                if (yearFortune[itemKey]) {

                    result.push(
                        <YearFortune1
                            key={"YearFortune" + yearFortune[itemKey].year}
                            year={yearFortune[itemKey].year}
                            age={yearFortune[itemKey].age}
                            yearfortune100Result={yearfortune100Param}
                            indexNumber={i}
                            //click
                            isClickable={true}
                            onChildClicked={onClickYearFortune}

                            skyStem={yearFortune[itemKey].yearPillar[0]}
                            earthBranch={yearFortune[itemKey].yearPillar[1]}
                            selectedYear={selectedYear}
                            selectedYearIndexNumber={selectedYearIndexNumber}

                            chi={yearFortune[itemKey].chi}

                            selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                            selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}

                        />
                    )
                }
            }
            return <div className='YF1container'>{result}</div>
        }
        return undefined
    }


    const onClickYearFortune = (yearfortune100Param, yearRangeParam, year, fullDate, indexNumber) => {

        //To dim the non-selected
        setSelectedYear(year)
        setSelectedMonth(0);//reset last selected item
        setSelectedYearIndexNumber(indexNumber)

        //To set data in parent
        props.setSelectedYear(year)
        props.setSelectedMonth(0);//reset last selected item


        setIsMonthsOpen(true)
    }


    const getMonthPillarFullName = (keyDate) => {


        // let dateObj = new Date(keyDate);

        // let month = dateObj.getMonth() + 1; // getMonth() is zero-based
        // let day = dateObj.getDate();

        // let formattedDate = `${month}月${day}日`;
        // return formattedDate

        return keyDate.substring(0, keyDate.indexOf('T'))

    }

    const drawMonthFortune = (yearfortune100Param, year) => {
        if (yearfortune100Param && yearfortune100Param.isSuccess) {
            let result = [];

            const monthFortuneStartDate = new Date(year, 1, 3)   //2月3日 is the day before 立春
            const monthFortuneEndDate = new Date(year + 1, 1, 3)   //2月3日 is the day before 立春

            for (let keyDate in yearfortune100Param.monthFortune) {
                const dateTimeOfMonth = new Date(keyDate)

                if (dateTimeOfMonth < monthFortuneEndDate && dateTimeOfMonth >= monthFortuneStartDate) {
                    const monthFortune = yearfortune100Param.monthFortune[keyDate]

                    result.push(
                        <YearFortune1
                            key={"MonthFortune" + keyDate}
                            yearfortune100Result={yearfortune100Param}

                            //click
                            isClickable={true}
                            onChildClicked={onClickMonthFortune}

                            fullDate={keyDate.substring(0, keyDate.indexOf('T'))}//+ " " + monthFortune.solarName}
                            skyStem={monthFortune.monthPillar[0]}
                            earthBranch={monthFortune.monthPillar[1]}
                            selectedFullDate={selectedMonth}

                            chi={monthFortune.chi}

                            selectedLetterIndex={props.selectedLetterIndex} setSelectedLetterIndex={props.setSelectedLetterIndex}
                            selectedLetter={props.selectedLetter} setSelectedLetter={props.setSelectedLetter} extraInfoMask={props.extraInfoMask}

                        />
                    )
                } else if (dateTimeOfMonth.getFullYear() > year) {
                    //do not loop the date larger than Clicked year
                    break
                }
            }
            return <div className='YF1container'>{result}</div>

        }
        return undefined
    }

    const onClickMonthFortune = (yearfortune100Param, yearRangeParam, year, fullDate) => {
        //To dim the non-selected
        setSelectedMonth(fullDate)

        //To set data in parent
        props.setSelectedMonth(fullDate)
        //scroll to the top when fortune months are in the bottom
        //window.scrollTo({ top: 0, behavior: 'smooth' });
    }






    return (
        <div>
            <details open={true}>
                <summary>大運(不使用虛歳)</summary>
                {drawYearFortune10(props.yearfortune100Result)}
            </details>

            <details open={isYearsOpen}>
                <summary>流年(不使用虛歳)</summary>
                {drawYearFortune1(props.yearfortune100Result, selectedYear10, drawFortune1EndYear) ?? <div>請先選擇大運</div>}

            </details>

            <details open={isMonthsOpen}>
                <summary>流月</summary>
                {drawMonthFortune(props.yearfortune100Result, selectedYear)}
            </details>



            {/* <div onClick={onClickTestselected10Year}>selected10Year</div>

            <div onClick={onClickTestyearRange}>Year Range</div>
            <div onClick={onClickTestyearfortune100Result}>yearfortune100Result</div> */}

        </div>
    )


}